<template lang="pug">
v-chip.chip(
	color="warning-container",
	variant="elevated",
	rounded="lg",
	size="small",
	text="beta",
	v-tooltip.bottom="'This feature is a beta preview'"
)
</template>

<script>
export default {
	name: 'BetaLabel'
};
</script>

<style scoped lang="scss">
@import '@mt360/client-core/styles/text-styles';

.chip {
	@extend %label-large;

	color: $tertiary40 !important;
}
</style>
