<template lang="pug">
v-toolbar.px-4(color="surface", rounded="lg")
	.headline2.text-truncate {{ appTitle }}
v-divider.mx-4
BaseTreeMenu(:items="menuItems")
</template>

<script>
import { useCmsStore } from '@/stores/cms.mjs';

export default {
	name: 'NavDrawerArticle',
	data() {
		return {
			menuItems: []
		};
	},
	computed: {
		appTitle() {
			return window.APP_TITLE;
		}
	},
	methods: {
		arrayToTree(arr, parent_item = null) {
			return arr
				.filter(item => item.parent_item === parent_item)
				.map(child => ({
					...child,
					children: this.arrayToTree(arr, child.id)
				}));
		}
	},
	async mounted() {
		try {
			// get menu items from store
			await this.cmsStore.getMenuItems();

			// add route entry
			const mappedItems = this.cmsStore.menuItems.data.map(item => ({
				...item,
				route: item.article.length ? `/articles/${item.article[0]}` : item.path
			}));

			// convert to tree
			this.menuItems = this.arrayToTree(mappedItems);
		} catch (e) {
			console.log(e);
		}
	},
	setup() {
		const cmsStore = useCmsStore();
		return { cmsStore };
	}
};
</script>
