<template lang="pug">
v-toolbar.px-4(color="surface", rounded="lg")
	.headline4.text-truncate {{ $t('navigation.headers.content') }}
v-divider.mx-4
v-list(density="compact", variant="flat", nav)
	v-list-item.pl-6.my-0(
		v-for="item in items",
		:variant="item.variant",
		:title="item.title",
		@click="$router.push(item.to)",
		rounded="xl"
	)
</template>
<script>
import { useCmsStore } from '@/stores/cms.mjs';
import { storeToRefs } from 'pinia';

export default {
	name: 'NavDrawerContent',
	data() {
		return {};
	},
	setup() {
		const store = useCmsStore();
		const { contentItems } = storeToRefs(store);

		return {
			contentItems
		};
	},
	computed: {
		items() {
			return this.contentItems.map(item => {
				return {
					id: item.id,
					title: item.title,
					ref: item.ref,
					to: item.to,
					variant: item.visible ? 'outlined' : 'text'
				};
			});
		}
	},
	methods: {
		scroll(ref) {
			document.getElementById(ref).scrollIntoView({ behavior: 'smooth' });
		}
	}
};
</script>
