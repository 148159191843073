import { defineStore } from 'pinia';
import Q from '@mt360/q';
import { directus } from '@/services/directus';

export const useCmsStore = defineStore('cms', {
	state: () => ({
		_contentItems: [],
		_menuItems: [],
		_articleList: {}
	}),
	getters: {
		contentItems(state) {
			return state._contentItems;
		},
		menuItems(state) {
			return state._menuItems;
		},
		article(state) {
			return articleId => {
				if (!articleId) return null;
				if (!state._articleList[articleId]) return null;
				return state._articleList[articleId];
			};
		}
	},
	actions: {
		setContentItems(items) {
			this._contentItems = items;
		},
		async getArticle(articleId) {
			try {
				this._articleList[articleId] = await directus.items('articles').readOne(articleId);
			} catch (e) {
				Q.diagOnce(`!getArticle`, e);
			}
		},
		async getMenuItems() {
			try {
				// get menu items from directus
				this._menuItems = await directus.items('menu_items').readByQuery({ limit: -1 });
			} catch (e) {
				Q.diagOnce(`!getMenuItems`, e);
			}
		}
	}
});
