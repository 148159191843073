<template lang="pug">
template(v-if="notDevelopment")
	v-menu(v-model="menu", location="bottom end", :close-on-content-click="true")
		template(v-slot:activator="{ props }")
			v-btn.ml-2(v-bind="props", rounded="normal", size="small")
				v-icon(icon="translate")
				v-icon(size="xs", icon="expand_more")
		//v-card(rounded="small",  )
		v-list.mt-2(density="compact", lines="one", min-width="145px")
			v-list-subheader {{ $t('languageSwitch.translations') }}
			template(v-for="(item, idx) of $i18n.availableLocales", :key="idx")
				v-list-item(
					:title="$t(`languageSwitch.${item}`)",
					:active="item === $i18n.locale",
					rounded="none",
					@click="$i18n.locale = item"
				)
</template>

<script>
export default {
	name: 'LanguageSwitch',
	data() {
		return {
			menu: false
		};
	},
	computed: {
		notDevelopment() {
			return import.meta.env.DEV;
		}
	}
};
</script>
