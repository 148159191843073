<template lang="pug">
.px-6.pb-6.d-flex.flex-column(style="min-height: 85vh")
	v-toolbar(color="surface")
		slot(name="toolbar")
	v-divider
	BaseTitle(:title="title", :subtitle="subtitle", :hint="hint", :dense="dense", :beta-label="betaLabel")
		template(v-slot:titleAction)
			slot(name="titleAction")
	slot
</template>

<script>
import BaseTitle from './BaseTitle.vue';
export default {
	components: {
		BaseTitle
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		hint: {
			type: String,
			default: ''
		},
		dense: {
			type: Boolean,
			default: false
		},
		betaLabel: {
			type: Boolean,
			default: false
		}
	}
};
</script>
