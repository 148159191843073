/* ===
	EXTEND Q with platform specific wrappers for BROWSERS
=== */
import Q from './index.mjs';

Q.iam = 'browser';

Q.btoa = function (o) {
	return btoa(o);
};
Q.atob = function (o) {
	return atob(o);
};
Q._JSON_fetch = function (opts, url, queryString, bodyString, cb) {
	//pass cookies and credentials to the fetch request
	// see: https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
	opts.credentials = 'include';

	if (bodyString) {
		opts.body = bodyString;
		opts.headers['Content-Length'] = new TextEncoder().encode(bodyString).length;
	}

	fetch(url + queryString, opts)
		.then(rsp => {
			rsp.text().then(txt => {
				cb(rsp.status, txt); // network ok, any http-Status codes signalled here!
			});
		})
		.catch(err => {
			Q.diagOnce('?_JSON_fetch.' + url, { err, url: url + queryString, opts });
			cb(970, err.message); // network error (such as CORS) encountered
		});
};

/** trigger a filedownload in a browser
 *
 * @param url:string
 * 			'data:text/html;charset=utf-8,' + encodeURIComponent(mytext)
 * 			'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(myobject)
 * 			'data:application/csv;charset=utf-8,' + encodeURIComponent(mycsv)
 * @param filename:string - optional
 */
Q.download = function (url, filename) {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display:none';
	a.href = url;
	a.download = filename;
	a.click();
	document.body.removeChild(a);
};

// treat text files
Q.downloadText = function (text, filename) {
	const url = 'data:application/csv;charset=utf-8,' + encodeURIComponent(text);
	Q.download(url, filename);
};

// treat binary files
Q.downloadBinary = function (bin, filename) {
	const blob = new Blob([bin], { type: 'octet/stream' });
	const url = window.URL.createObjectURL(blob);
	try {
		Q.download(url, filename);
	} finally {
		window.URL.revokeObjectURL(url);
	}
};

Q.asy_blobToDataURL = async function (blob) {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(blob);
	});
};

// global error event watchers -> to do some health monitoring...
// some more to consider.... developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection
const _window = typeof window !== 'undefined' ? window : self;
_window.addEventListener('unhandledrejection', function (ev) {
	//debugger;
	Q.diagOnce('!window.unhandledrejection.' + ev.reason.message, {
		...ev,
		stack: ev.reason.stack
	});
	Q.log('!window.unhandledrejection.' + ev.reason.message, {
		...ev,
		stack: ev.reason.stack
	});
});
_window.addEventListener('error', function (ev) {
	//debugger;
	Q.diagOnce('!window.error.' + ev.message, { ...ev, stack: ev.error.stack });
	Q.log('!window.error.' + ev.reason.message, {
		...ev,
		stack: ev.reason.stack
	});
});

export default Q;
