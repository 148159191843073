import '@mt360/q';
import '@mt360/q/browser';
import './styles/core.scss';

//set the app title based on the global env
document.title = `${import.meta.env.VITE_APP_TITLE} | ${import.meta.env.VITE_SYSTEM_NAME}`;

//calculate available screen height (differs on mobile browsers)
const resizeOps = () => {
	document.documentElement.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
};

resizeOps();
window.addEventListener('resize', resizeOps);

//some global vars
window.APP_TITLE = import.meta.env.VITE_APP_TITLE || '';
