<template lang="pug"></template>

<script>
/* global Sanitizer */
//This tooltip directive is originally developed for the rapidM2M Studio

let sanitizer = null;
//Sanitizer is supported from chrome 105 onwards
if ('Sanitizer' in window) {
	const s = new Sanitizer();
	//this function is not supported in all browser versions
	if (typeof s.sanitizeFor !== 'undefined') sanitizer = s;
}

//hold all created tooltip nodes globally!
const __$_tooltipList = (window.__$_tooltipList = {});

/**
 * Show the tooltip after 300 milliseconds
 * @param el - the element where the tooltip should be attached
 */
function showTimeout(el) {
	const tt = __$_tooltipList[el.__$__tt_id];
	if (!tt) return;
	clearTimeout(tt.__$__tt_hide);
	tt.__$__tt_hide = null;
	if (tt.__$__tt_show) return;
	tt.__$__tt_show = setTimeout(() => {
		showTooltip(el);
		tt.__$__tt_show = null;
	}, 300);
}

/**
 * Hide the tooltip after 500 milliseconds
 * @param el
 */
function hideTimeout(el) {
	const tt = __$_tooltipList[el.__$__tt_id];
	if (!tt) return;
	clearTimeout(tt.__$__tt_show);
	tt.__$__tt_show = null;
	if (tt.__$__tt_hide) return;
	tt.__$__tt_hide = setTimeout(() => {
		hideTooltip(el);
		tt.__$__tt_hide = null;
	}, 500);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function updateTooltip(el, content) {
	const tt = __$_tooltipList[el.__$__tt_id];
	if (!tt) return;

	//if no tooltip value is provided use the initial value
	if (!content) content = tt.__$__tt_value;

	if (content) {
		//remove existing tooltip content...
		tt.innerHTML = '';
		const txt = document.createElement('span');
		txt.className = 'q-tooltip-text q-tooltip-text-' + tt.__$__tt_dir;
		if (sanitizer) {
			const sanitized = sanitizer.sanitizeFor('div', content);
			txt.innerHTML = sanitized.innerHTML;
		} else {
			txt.innerHTML = content;
		}
		tt.__$__tt_value = content;

		tt.appendChild(txt);
	}

	const $parentBounds = el.getBoundingClientRect();
	const txt = tt.childNodes[0]; //tt can only hold one span!

	const cw = txt.clientWidth;
	const ch = txt.clientHeight;

	let ox = $parentBounds.left;
	let oy = $parentBounds.top;

	const dir = tt.__$__tt_dir;

	if (dir === 'l') {
		ox += -10 - cw;
		oy += $parentBounds.height / 2 - ch / 2;
	}
	if (dir === 'r') {
		ox += 10 + $parentBounds.width;
		oy += $parentBounds.height / 2 - ch / 2;
	}
	if (dir === 't') {
		ox += $parentBounds.width / 2 - cw / 2;
		oy += -10 - ch;
	}
	if (dir === 'b') {
		ox += $parentBounds.width / 2 - cw / 2;
		oy += $parentBounds.height + 10;
	}
	if (dir === 'tr') {
		ox += $parentBounds.width / 2 - 10;
		oy += -10 - ch;
	}
	if (dir === 'trs') {
		ox += 0;
		oy += -10 - ch;
	}
	if (dir === 'tl') {
		ox += $parentBounds.width / 2 - cw + 10;
		oy += -10 - ch;
	}
	if (dir === 'tle') {
		ox += $parentBounds.width - cw + 10;
		oy += -10 - ch;
	}
	if (dir === 'br') {
		ox += $parentBounds.width / 2 - 10;
		oy += $parentBounds.height + 10;
	}
	if (dir === 'bl') {
		ox += $parentBounds.width / 2 - cw + 10;
		oy += $parentBounds.height + 10;
	}
	if (dir === 'ble') {
		ox += $parentBounds.width - cw + 10;
		oy += $parentBounds.height + 10;
	}
	if (dir === 'brs') {
		ox += 0;
		oy += $parentBounds.height + 10;
	}

	tt.style.left = ox.toFixed(0) + 'px';
	tt.style.top = oy.toFixed(0) + 'px';
	tt.classList.add('q-tooltip-text');
}

function showTooltip(el) {
	const tt = __$_tooltipList[el.__$__tt_id];
	if (!tt) return;
	//add tooltip to the document
	if (!document.getElementById(el.__$__tt_id)) {
		document.body.appendChild(tt);
	}
	//update the position
	updateTooltip(el);
	tt.style.opacity = 1;
}

function createTooltip(el, binding) {
	if (!el.__$__tt_id) {
		el.__$__tt_id = `tooltip_${Math.random().toString(36).substr(2, 10)}`; //Random id
	}
	const tt_id = el.__$__tt_id;
	const tt = __$_tooltipList[tt_id] ? __$_tooltipList[tt_id] : document.createElement('div');

	let dir = 't'; // (t)op,(b)ottom,(l)eft,(r)ight|(e)end
	if (binding.modifiers.bottom) dir = 'b';
	if (binding.modifiers.left) dir = 'l';
	if (binding.modifiers.right) dir = 'r';
	if (binding.modifiers.topright) dir = 'tr';
	if (binding.modifiers.toprightstart) dir = 'trs';
	if (binding.modifiers.topleftend) dir = 'tle';
	if (binding.modifiers.topleft) dir = 'tl';
	if (binding.modifiers.bottomright) dir = 'br';
	if (binding.modifiers.bottomleft) dir = 'bl';
	if (binding.modifiers.bottomleftend) dir = 'ble';
	if (binding.modifiers.bottomrightstart) dir = 'brs';

	tt.__$__tt_dir = dir;
	tt.__$__tt_value = binding.value;

	tt.id = tt_id;
	tt.className = 'q-tooltip';

	//only create mouse listeners if the tooltip is not created yet
	if (!__$_tooltipList[tt_id]) {
		//todo try aborting the eventlisteners with AbortController
		el.addEventListener('mouseenter', () => {
			showTimeout(el);
		});
		el.addEventListener('mousemove', () => {
			showTimeout(el);
		});
		el.addEventListener('mouseleave', () => {
			hideTimeout(el);
		});
	}
	//cache the tooltip
	__$_tooltipList[tt_id] = tt;
	//updateTooltip(el, binding.value);
}

function hideTooltip(el) {
	const elemToRemove = document.getElementById(el.__$__tt_id);
	if (elemToRemove) {
		clearTimeout(elemToRemove.__$__tt_remove);
		elemToRemove.style.opacity = 0;
		elemToRemove.__$__tt_remove = setTimeout(() => {
			elemToRemove.remove();
		}, 500); //wait till the item is disappeared
	}
}

function removeTooltip(el) {
	const tt = __$_tooltipList[el.__$__tt_id];
	//todo remove the mouse event listeners!
	if (!tt) return;
	tt.remove();
	delete __$_tooltipList[el.__$__tt_id];
}

/**
 * Possible binding values:
 * 	bottom
 * 	left
 * 	right
 * 	topright
 * 	toprightstart
 * 	topleftend
 * 	topleft
 * 	bottomright
 * 	bottomleft
 * 	bottomleftend
 * 	bottomrightstart
 */
export default {
	mounted(el, binding) {
		if (!binding.value) return;
		createTooltip(el, binding);
	},
	updated(el, binding) {
		//updates the tooltip content
		if (binding.value) {
			updateTooltip(el, binding.value);
		} else {
			//removeTooltip(el);
			hideTooltip(el);
		}
	},
	unmounted(el) {
		removeTooltip(el);
		hideTooltip(el);
	}
};
</script>

<style lang="scss">
@import '@mt360/client-core/styles/variables';

// tooltip on parent /w light theme (=default)
$q-tooltip-bgl-90: #424659;
$q-tooltip-bgl-50: #00000080;
$q-tooltip-fgl: #fff;

// tooltip on parent /w dark theme
$q-tooltip-bgd-90: #424659;
$q-tooltip-bgd-90: #00000080;
$q-tooltip-fgd: #fff;

.q-tooltip {
	font-family: $font-family, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	position: absolute;
	z-index: 2147483638; // highest possible value!
	opacity: 0;
	background-color: $q-tooltip-bgl-90;
	transition: opacity 0.1s linear; // fade-in tooltip

	> .q-tooltip-text {
		position: fixed;
		visibility: visible;
		box-shadow: 5px 5px 10px 0 $q-tooltip-bgl-50;
		background-color: $q-tooltip-bgl-90;
		color: $q-tooltip-fgl;
		border-radius: 3px;
		z-index: 1;
		padding: 6px 8px;
	}

	// tooltip arrow
	> .q-tooltip-text::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: $q-tooltip-bgl-90 transparent transparent transparent;
	}

	> .q-tooltip-text.q-tooltip-text-r::after {
		left: -5px;
		top: calc(25% + 5px);
		transform: rotate(90deg);
	}

	> .q-tooltip-text.q-tooltip-text-l::after {
		left: 100%;
		top: calc(25% + 5px);
		margin-left: 0;
		transform: rotate(270deg);
	}

	> .q-tooltip-text.q-tooltip-text-b::after {
		left: 50%;
		top: -10px;
		transform: rotate(180deg);
	}

	> .q-tooltip-text.q-tooltip-text-tr::after {
		left: 10px;
	}

	> .q-tooltip-text.q-tooltip-text-trs::after {
		left: 10px;
	}

	> .q-tooltip-text.q-tooltip-text-tl::after {
		left: calc(100% - 10px);
	}

	> .q-tooltip-text.q-tooltip-text-tle::after {
		left: calc(100% - 10px);
	}

	> .q-tooltip-text.q-tooltip-text-br::after {
		left: 10px;
		top: -10px;
		transform: rotate(180deg);
	}

	> .q-tooltip-text.q-tooltip-text-bl::after {
		left: calc(100% - 10px);
		top: -10px;
		transform: rotate(180deg);
	}

	> .q-tooltip-text.q-tooltip-text-ble::after {
		left: calc(100% - 10px);
		top: -10px;
		transform: rotate(180deg);
	}

	> .q-tooltip-text.q-tooltip-text-brs::after {
		left: 10px;
		top: -10px;
		transform: rotate(180deg);
	}
}

/* stylelint-disable-next-line */
.theme--light .q-tooltip {
	> .q-tooltip-text {
		box-shadow: 5px 5px 10px 0 $q-tooltip-bgl-50;
		background-color: $q-tooltip-bgd-90;
		color: $q-tooltip-fgl;
	}

	> .q-tooltip-text::after {
		border-color: $q-tooltip-bgl-90 transparent transparent transparent;
	}
}

/* stylelint-disable-next-line */
.theme--dark .q-tooltip {
	> .q-tooltip-text {
		box-shadow: 5px 5px 10px 0 $q-tooltip-bgd-90;
		background-color: $q-tooltip-bgd-90;
		color: $q-tooltip-fgd;
	}

	> .q-tooltip-text::after {
		border-color: $q-tooltip-bgd-90 transparent transparent transparent;
	}
}
</style>
