<template lang="pug">
div
	v-hover(v-slot="{ isHovering, props }")
		v-card.card.px-4.py-2.align-center(
			v-bind="handleMouseProps(props)",
			:width="width",
			:height="height",
			density="comfortable",
			ref="base-card",
			:variant="variant",
			:elevation="getElevation(isHovering)",
			:style="borderColor",
			:class="{ 'card-empty': empty, 'skeleton-box': loading, hover: clickListener }",
			:color="backgroundColor",
			rounded="md"
		)
			v-card-text.ma-0.pa-2(v-if="empty && !loading")
				.body1.font-weight-regular {{ title }}
			slot
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		loading: {
			default: false,
			type: Boolean
		},
		empty: {
			default: false,
			type: Boolean
		},
		elevation: {
			type: Number,
			default: null
		},
		variant: {
			type: String,
			default: undefined
		},
		color: {
			type: String,
			default: '#C8C6CA'
		},
		backgroundColor: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: ''
		},
		height: {
			type: String,
			default: ''
		}
	},
	computed: {
		borderColor() {
			return { 'border-left-color': this.mapColorString() };
		},
		clickListener() {
			return !!this.$attrs.onClick;
		}
	},
	methods: {
		handleMouseProps(props) {
			return props;
		},
		mapColorString() {
			//get the hex color of the vuetify name
			return this.$vuetify?.theme?.current?.colors[this.color] || this.color;
		},
		getElevation(hovering) {
			if (this.elevation !== null) return this.elevation;
			if (this.empty) return 0;
			else return hovering ? 4 : 1;
		}
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
.card {
	border-radius: 3px;
	border-color: #c8c6ca;
	border-left: 4px solid;
	min-height: 56px;

	&.hover {
		cursor: pointer;
	}
}

.card-empty {
	background: #e4e1e6;
	box-shadow: none;
}

.card-text {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.5px;
	color: #303034;
}
</style>
