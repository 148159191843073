<template lang="pug">
v-snackbar(:model-value="needRefresh", timeout="-1", vertical, location="bottom right")
	.d-flex.body2 {{ $t('reloadPrompt.texts.newContent1') }}
		.font-weight-bold &nbsp;{{ $t('reloadPrompt.actions.refresh') }}&nbsp;
		| {{ $t('reloadPrompt.texts.newContent2') }}
	.d-flex.flex-row.justify-end.mt-2
		//v-btn.mr-2(variant='text', @click='close') {{$t('reloadPrompt.actions.close')}}
		v-btn(color="primary", @click="updateServiceWorker()") {{ $t('reloadPrompt.actions.refresh') }}
</template>

<script>
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { registerSW } from 'virtual:pwa-register';
export default {
	name: 'ReloadPrompt',
	setup() {
		const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();

		const close = async () => {
			offlineReady.value = false;
			needRefresh.value = false;
		};

		//periodic validation for a new service worker
		const intervalMS = 60 * 60 * 1000;

		registerSW({
			onRegistered(r) {
				r &&
					setInterval(() => {
						r.update();
					}, intervalMS);
			}
		});

		return { offlineReady, needRefresh, updateServiceWorker, close };
	},
	data() {
		return {};
	}
};
</script>
