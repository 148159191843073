// determine the "global" object - either "window.xxx" on browsers or "global.xxx" in node.js
const is_worker = typeof window === 'undefined';
const is_client = typeof global === 'undefined';

const g = is_client ? (is_worker ? self : window) : global;

if (g.assert) console.error('assert already defined!', g.assert);

/**
 *
 * @param cond:boolean
 * @param msg:[*]
 */
function assert(cond, ...msg) {
	if (!cond) {
		Q.log('!' + msg.join('; '));
		throw new Error(JSON.stringify(msg));
	}
}

if (!g.assert) g.assert = assert;

const Q = {};
//make Q lib global!
if (!g.Q) {
	g.Q = Q;
}
export default Q;

Q.is_client = is_client;
Q.VERBOSE_ITF = false;
Q.is_cluster = false;

class QError extends Error {}

Q.is = {
	QError: err => {
		return err instanceof QError;
	},
	array: v => {
		return v && Array.isArray(v);
	},
	arrayNotEmpty: v => {
		return v && Array.isArray(v) && v.length;
	},
	boolean: v => {
		return typeof v === 'boolean';
	},
	function: v => {
		return typeof v === 'function';
	},
	functionOrNull: v => {
		return v === null || typeof v === 'function';
	},
	NaN: v => {
		return isNaN(v);
	},
	hex: v => {
		return /^[a-fA-F0-9]*$/.test(v);
	},
	number: v => {
		return typeof v === 'number' || typeof v === 'bigint';
	},
	numberInt: v => {
		if (typeof v === 'bigint') return true;
		return typeof v === 'number' && Math.trunc(v) === v;
	},
	numeric: v => {
		return !Array.isArray(v) && v - parseFloat(v) + 1 >= 0;
	},
	numericInt: v => {
		if (typeof v === 'bigint') return true;
		if (v === '') return false;
		try {
			BigInt('' + v); // throws an error if v is not integer-like
			return true;
		} catch (err) {
			return false;
		}
		/* todo rem - old school... const vs = ('' + v).trim();
		return vs === '' + parseInt(vs); */
	},
	numberNotZero: v => {
		return v && Q.is.number(v);
	},
	object: v => {
		return !!v && typeof v === 'object' && !Q.is.array(v);
	},
	objectOrNull: v => {
		return v === null || Q.is.object(v);
	},
	objectEmpty: v => {
		return v === null || (Q.is.object(v) && !Object.keys(v).length);
	},
	objectNotEmpty: v => {
		return Q.is.object(v) && !!Object.keys(v).length;
	},
	string: v => {
		return typeof v === 'string';
	},
	stringNotEmpty: v => {
		return v && typeof v === 'string';
	},
	date: v => {
		return new Date(v) !== 'Invalid Date' && !isNaN(new Date(v));
	},
	email: v => {
		return !!v.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/);
	},
	// https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
	domain: v => {
		return !!v.match(
			/^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/
		);
	},
	// identifies forbidden characters
	filename: v => {
		return !!v.match(/^[^\\/:*?"<>|]+$/); // forbidden characters \ / : * ? " < > |
	},
	serialNumber: (v, isHex = true) => {
		if (isHex) return v && v.length === 16 && Q.is.hex(v);
		else return v && v.length === 16;
	},
	verificationCode: v => {
		return v && v.length === 10;
	}
};

/**
 * @param list:[]]|{}
 * @param cb:function(val,key):boolean -  eturns true when val/key matches find criteria
 * @return null|* - null if nothing found, otherwise value
 */
Q.find = function (list, cb) {
	if (Q.is.array(list)) {
		return list.find(cb);
	} else if (Q.is.object(list)) {
		for (const k in list) {
			if (list[k] && cb(list[k], k)) return list[k];
		}
	} else assert(list === null, 'array or object required');
	return null;
};

Q.clearTimeout = function (obj, tmr) {
	assert(Q.is.objectNotEmpty(obj));
	assert(Q.is.stringNotEmpty(tmr));

	if (obj[tmr]) {
		clearTimeout(obj[tmr]); // cancel ws open timeout
		obj[tmr] = null;
	}
};

Q.asyncTimeout = async function (ms) {
	return new Promise(resolve => {
		setTimeout(resolve, ms);
	});
};

Q.nextTick = async function () {
	return new Promise(resolve => {
		setImmediate(resolve);
	});
};

const _deferrals = {}; // <token>:{ handler:func, tmr:timeoutHandle }
Q.defer = function (token, ms, cb) {
	assert(Q.is.stringNotEmpty(token));
	assert(Q.is.numberInt(ms));
	assert(Q.is.function(cb));

	const d = _deferrals[token];
	if (d) clearTimeout(d.tmr);
	_deferrals[token] = {
		tmr: setTimeout(() => {
			delete _deferrals[token];
			cb();
		}, ms),
		handler: cb
	};
};

// call any pending callback immediately
Q.clearDefer = function (token, execIfPending) {
	assert(Q.is.stringNotEmpty(token));
	const d = _deferrals[token];

	if (!d) {
		// no defer operation pending -> silently abort
		Q.log(`?Q.clearDefer - no pending operation found for "${token}"`);
		return;
	}
	clearTimeout(d.tmr);
	delete _deferrals[token];
	if (execIfPending)
		return new Promise(resolve => {
			resolve(d.handler());
		});
};

/** convert from ms to rapidM2M's often used stamp32 format
 * @param ms:number
 * @return :number - 1s resolution, 0=31.12.1999 00:00:00
 */
const MS_1999_12_31 = Date.UTC(1999, 11, 31, 0, 0, 0);
Q.msToStamp32 = function (ms) {
	assert(Q.is.numberInt(ms));

	if (!ms) ms = Date.now();

	const t = (ms - MS_1999_12_31) / 1000;
	return Math.round(t);
};

/**
 * @param ms:number - date/time value to convert into string; falsy = now
 * @param resolution:string - optional; 'y','m','d','h','n','s','z' least significant date/time unit to inlcude; default='s'
 * @param delim:string - optional; delimiter between date and time; default=' '
 * @param utc:boolean - option; change the return date to the locale time
 * @returns string
 */
Q.stampToString = function (ms = 0, resolution = 's', delim = ' ', utc = true) {
	if (!ms) ms = Date.now();
	if (!utc) {
		ms += -60 * 1000 * new Date().getTimezoneOffset();
	}
	const s = new Date(ms).toISOString();

	if (delim === undefined) delim = ' ';
	const r = {
		z: 23,
		s: 19,
		n: 16,
		h: 13,
		d: 10,
		m: 7,
		y: 4
	}[resolution];
	return s.substr(0, r || 19).replace('T', delim);
};

/**
 * fill up with left/right spaces to grant length of <n> chars
 * @param val_or_str- numeric or string value
 * @param align_and_length - total number of characters to grant
 * 				>0 right aligned <v>
 * 			    <0 left aligned <v>
 * @param padchar - String to append or prepend default ' '
 * @returns {string}
 */
Q.toString = function (val_or_str, align_and_length, padchar = ' ') {
	assert(align_and_length !== undefined);
	const s = '' + val_or_str;
	const pn = Math.max(0, Math.abs(align_and_length) - s.length);
	const padding = padchar.repeat(pn);
	return align_and_length < 0 ? s + padding : padding + s; // left vs right align
};
Q.toHex = function (n, digits) {
	return ('0000000000000000' + n.toString(16)).substr(-digits);
};

/**
 * convert anything into u8 array - keeps byteOffset/byteLength if applicable!
 * @param buf
 * @result Uint8Array
 */
Q.to_Uint8Array = function (buf) {
	if (buf instanceof Uint8Array) return buf;
	if (buf instanceof ArrayBuffer) return new Uint8Array(buf);
	//convert streamed nodejs buffer to uint8
	if (buf && buf.type && buf.type === 'Buffer' && buf.data && buf.data instanceof Array)
		return Uint8Array.from(buf.data);
	else if (buf.buffer) return new Uint8Array(buf.buffer, buf.byteOffset, buf.byteLength);
	assert(false);
};

/**
 * Get a random number between min and max (inclusive)
 * @param min {Number}
 * @param max {Number}
 * @return {number}
 */
Q.getRandomIntInclusive = function (min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
};

/**
 * convert any type of error information into normalized structure {code:string, info:{*}}
 * @param err:any
 * @return {code,info}
 */
Q.normalizeErr = function (err) {
	function __normalizeMsg(e) {
		if (e.message && typeof e.message !== 'object') {
			e.message = {
				err: e.message
			};
		}
		return e;
	}

	if (!err) return err;

	if (Q.is.QError(err)) {
		return __normalizeMsg(err);
	}

	// handle axios errors
	if (err.isAxiosError) {
		if (err.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			return __normalizeMsg({
				code: err.response.status,
				message: err.response.data,
				info: {
					class: err.constructor.name,
					method: err.config.method,
					url: err.config.url
				}
			});
		} else if (err.request) {
			// The request was made but no response was received (e.g. Timeout)
			return __normalizeMsg({
				code: err.code,
				message: err.message,
				info: {
					class: err.constructor.name,
					method: err.config.method,
					url: err.config.url
				}
			});
		}
	}

	if (err instanceof Error) {
		// convert Error object into something transmitable
		return __normalizeMsg({
			code: err.code || 'E_INTERNAL',
			message: err.message,
			info: {
				class: err.constructor.name,
				name: err.name,
				stack: err.stack
			}
		});
	}

	if (typeof err === 'object') {
		return __normalizeMsg({
			code: err.code || err.err,
			message: err.message || err.code || err.err,
			info: err
		});
	}

	return __normalizeMsg({
		code: '' + err,
		message: '' + err,
		info: err
	});
};

Q.stringifyErr = function (err) {
	return JSON.stringify(Q.normalizeErr(err));
};

Q.throw = function (code, msg, ...info) {
	assert(Q.is.stringNotEmpty(code) || Q.is.number(code));
	assert(msg === undefined || Q.is.stringNotEmpty(msg));

	const err = new QError(msg || code);
	err.code = code;
	err.info = info.length > 1 ? info : info[0];
	throw err;
};

Q.CONSOLE = {
	RESET: '\x1b[0m',
	BOLD: '\x1b[1m',
	UNDERLINE: '\x1b[4m',
	REVERSE: '\x1b[7m', // reverse bg/fg colors
	// DIM    : "\x1b[2m",		// n.a.
	// BLINK : "\x1b[5m",		// n.a.
	// HIDDEN : "\x1b[8m",		// n.a.

	BLACK: '\x1b[30m',
	RED: '\x1b[31m',
	GREEN: '\x1b[32m',
	YELLOW: '\x1b[33m',
	BLUE: '\x1b[34m',
	MAGENTA: '\x1b[35m',
	CYAN: '\x1b[36m',
	WHITE: '\x1b[37m',

	BG_BLACK: '\x1b[40m',
	BG_RED: '\x1b[41m',
	BG_GREEN: '\x1b[42m',
	BG_YELLOW: '\x1b[43m',
	BG_BLUE: '\x1b[44m',
	BG_MAGENTA: '\x1b[45m',
	BG_CYAN: '\x1b[46m',
	BG_WHITE: '\x1b[47m'
};

Q.logFilter = () => true;

let _log_stamp_ = Date.now();
/**
 * @param args:<any> -
 * 		args[0], first char: '!'=error/alert, '?'=warning, '~'=debug/verbose, any other=regular log
 * 		args[n] '#red', '#green', '#yellow', '#blue', '#magenta', '#cyan', '#inverse', '#normal' to highlight all following string arguments
 * 		args[n] '~red', '~green', '~yellow', '~blue', '~magenta', '~cyan', '~inverse', '~normal' to change textcolor of all following string arguments
 *				note: chrome client allows colors tied to the very first string argument only; (only 1 color per log)
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
Q.log = function (...args) {
	// filter ignored logs
	if (!Q.logFilter(...args)) return;

	const now = Date.now();
	const stamp = Q.toString(now - _log_stamp_, 5);
	let arg0 = args.shift();

	let p = ' '; // default = .log
	if (typeof arg0 === 'string') {
		if (arg0[0] === '#') {
			args.unshift((is_client ? '' : p) + stamp);
			args.unshift(arg0); // color code!
			args.unshift('#log');
		} else {
			if ('!?~'.includes(arg0[0])) {
				p = arg0[0];
				arg0 = arg0.substr(1);
			}

			args.unshift(arg0);
			args.unshift((is_client ? '' : p) + stamp);
			args.unshift({ '!': '#error', '?': '#warn', '~': '#debug', ' ': '#log' }[p]);
		}
	} else {
		args.unshift(arg0);
		args.unshift((is_client ? '' : p) + stamp);
		args.unshift('#log');
	}

	const f = { '!': 'log', '?': 'log', '~': 'info', ' ': 'log' }[p];

	if (is_client) {
		/* chrome: only the very first arg can be formatted (but multiple formattings possible)
				ok: '%c1111 %c2222','color:red','color:green'
				nok: '%c1111','%c2222','color:red','color:green'
				nok: '%c1111','color:red','%c2222','color:green'		*/
		const out = [];
		const cos = [];
		let cowarns = 0;
		let coflag = '';
		const out0 = [];
		for (const arg of args) {
			const COLS = {
				'#error': '',
				'#warn': '',
				'#debug': 'color:#aaa;padding-left:10px',
				'#log': 'padding-left:10px',

				'#red': 'color:white;background:red',
				'#green': 'color:white;background:green',
				'#yellow': 'color:black;background:yellow',
				'#blue': 'color:white;background:blue',
				'#magenta': 'color:white;background:magenta',
				'#cyan': 'color:black;background:cyan',
				// eslint-disable-next-line sonarjs/no-duplicate-string
				'#black': 'color:white;background:black',
				'#purple': 'color:white;background:purple',
				'#white': 'color:black;',
				'#reverse': 'color:white;background:black',
				'#inverse': 'color:white;background:black',
				// eslint-disable-next-line sonarjs/no-duplicate-string
				'#normal': 'color:black',
				'#default': 'color:black',
				'~red': 'color:red',
				'~green': 'color:green',
				'~yellow': 'color:yellow',
				'~blue': 'color:blue',
				'~magenta': 'color:magenta',
				'~cyan': 'color:cyan',
				'~reverse': 'color:white;background:black',
				'~inverse': 'color:white;background:black',
				'~normal': 'color:black',
				'~default': 'color:black'
			};
			if (COLS[arg] !== undefined) {
				if (out.length) cowarns++;
				else if (COLS[arg]) {
					coflag += '%c';
					cos.push(COLS[arg]);
				}
			} else if (!out.length && typeof arg === 'string') {
				out0.push(coflag + arg); // collect all strings from the left into out0
				coflag = '';
			} else {
				out.push(arg);
				if (coflag) cowarns = 1;
			}
		}
		if (cos.length) console[f](out0.join(' '), ...cos, ...out);
		else if (out0.length) console[f](out0.join(' '), ...out);
		else console[f](...out);

		if (cowarns) console.warn(`\t\t^--- colors are allowed for the first string argument only!`);
	} else {
		const out = [];
		let co = '';
		for (const arg of args) {
			const COLS = {
				'#error': Q.CONSOLE.REVERSE + Q.CONSOLE.RED,
				'#warn': Q.CONSOLE.REVERSE + Q.CONSOLE.YELLOW,
				'#debug': Q.CONSOLE.BLUE,
				'#log': '',

				'#red': Q.CONSOLE.REVERSE + Q.CONSOLE.RED,
				'#green': Q.CONSOLE.REVERSE + Q.CONSOLE.GREEN,
				'#yellow': Q.CONSOLE.REVERSE + Q.CONSOLE.YELLOW,
				'#blue': Q.CONSOLE.REVERSE + Q.CONSOLE.BLUE,
				'#magenta': Q.CONSOLE.REVERSE + Q.CONSOLE.MAGENTA,
				'#cyan': Q.CONSOLE.REVERSE + Q.CONSOLE.CYAN,

				'#black': Q.CONSOLE.RESET,
				'#white': Q.CONSOLE.REVERSE + Q.CONSOLE.WHITE,

				'#reverse': Q.CONSOLE.REVERSE + Q.CONSOLE.WHITE,
				'#inverse': Q.CONSOLE.REVERSE + Q.CONSOLE.WHITE,
				'#normal': Q.CONSOLE.RESET,
				'#default': Q.CONSOLE.RESET,
				'~red': Q.CONSOLE.RED,
				'~green': Q.CONSOLE.GREEN,
				'~yellow': Q.CONSOLE.YELLOW,
				'~blue': Q.CONSOLE.BLUE,
				'~magenta': Q.CONSOLE.MAGENTA,
				'~cyan': Q.CONSOLE.CYAN,
				'~reverse': Q.CONSOLE.REVERSE + Q.CONSOLE.WHITE,
				'~inverse': Q.CONSOLE.REVERSE + Q.CONSOLE.WHITE,
				'~normal': Q.CONSOLE.RESET,
				'~default': Q.CONSOLE.RESET
			};
			if (Q.is.string(arg)) {
				if (COLS[arg] !== undefined) co = COLS[arg];
				else if (co) out.push(co + arg + Q.CONSOLE.RESET);
				else out.push(arg);
			} else out.push(arg);
		}
		const out0 = out[0];
		out.shift();
		if (Q.is_cluster) out.unshift(`w${Q.is_cluster}`);
		console[f](out0, ...out);
	}

	_log_stamp_ = now;
};

/** enable Q.diagOnce feature
 *  - resolves dynamic data of Error objects to stringify-able static data
 *  - keeps list of already fetched diag tokens to avoid multiple forwards
 *
 * @param onForward:function(topic:string,info:object) - only called upon very first occurance of a diag token
 */
Q.diagInit = function (onForward) {
	assert(!Q.diagOnce);
	assert(!Q.diag);

	Q.diag = (topic, info) => {
		onForward(topic, info);
	};

	Q._diagTopics = {};
	Q.diagOnce = (topic, info) => {
		if (Q._diagTopics[topic]) {
			Q._diagTopics[topic]++;
			return;
		}
		Q._diagTopics[topic] = 1;
		onForward(topic, info);
	};
};

/**
 * SIMPLE EVENT DISPATCHER
 * -----------------------
 * typ. used to derive own event-able classes
 *
 * EXAMPLE
 *
 * derive my own class
 * 		class Me extends QEvents { ... }
 * create an instance of my class
 * 		const me= new Me();
 * register 2 listeners for event 'test'
 * 		me.on('test',()=>{ console.log( '-1-', ...arguments); });
 * 		me.on('test',()=>{ console.log( '-2-', ...arguments); });
 * fire event 'test' which will immediately call all registered listeners
 * 		me.emit('test',1,'xyz');
 */

let gsubs_id_ = 1111; // next subscription id to assign upon "on"
const gsubs_ = {}; // <sub_id>:[evt,handler,once,opts]

Q.QEvents = class {
	constructor() {
		// todo rem this._subs={};		// <subid>:[evt,domain,listener,once] - list of ALL registered events
		this._events = {}; // <evt>:[<subid>,...] - shorthand list for fast "per event" traversing
		this.hookBefore = null;
		this.hookAfter = null;
	}

	destroy() {
		// at this point there should remain anonymous subs only!
		// (e.g. w/o offxsel - typ. those which are eracted by the emitter internally)
		assert(!this.hasListeners_with_offxsel());
		// get rid of all remaining (anonymous) subs sourced by this emitter
		this.offx(null);
	}

	on(evt, listener, opts, ___once = 0) {
		assert(Q.is.stringNotEmpty(evt));
		assert(Q.is.function(listener));

		const sub_id = ++gsubs_id_;

		if (!this._events[evt]) this._events[evt] = [];
		const evt_subs = this._events[evt];

		const twin = Q.find(evt_subs, sub_id => {
			const gsub = gsubs_[sub_id];
			return gsub && gsub[1] === listener;
		});
		assert(!twin, `same listener registered twice for event "${evt}"`);

		gsubs_[sub_id] = [evt, listener, ___once, opts, this]; // register sub globally
		// todo rem this._subs[sub_id]= sub;				// register sub in emitter's full list
		evt_subs.push(sub_id); // register sub in emitter's "per evt" fast list

		return sub_id;
	}
	onx(offxsel, evt, listener) {
		return this.on(evt, listener, { offxsel: offxsel });
	}
	/**
	 * emitter-centric remove of offxsel's subscriptions
	 * - optionally filtered by evt
	 *
	 * @param offxsel:any|null
	 * 				- null to remove *all* subscriptions pointing to this emitter
	 * 				- otherwise limit to certain offxsel
	 * @param evt:string - optional, additional filter; may be combined even with offxsel===null
	 */
	offx(offxsel, evt = null) {
		Q.QEvents.offx(offxsel, evt, this);
	}

	/**
	 * global remove of offxsel's subscriptions
	 * - optionally filtered by evt
	 *
	 * @param offxsel:any - hidden feature: may be null, if __emitter is specified instead!
	 * @param evt:string|null
	 * @param __emitter:Q.QEvents - hidden param to implement emitter centric (none-static) offx
	 */
	static offx(offxsel, evt = null, __emitter = null) {
		assert(offxsel !== undefined);
		assert(offxsel || __emitter);
		for (const subid in gsubs_) {
			const [gevt /* ghandler */ /* gonce */, , , gopts, gemitter] = gsubs_[subid];
			if (offxsel && !gopts) continue;
			if (offxsel && gopts.offxsel !== offxsel) continue;
			if (evt && evt !== gevt) continue;
			if (__emitter && __emitter !== gemitter) continue;
			gemitter.off(subid);
		}
	}

	// todo rem after dev???
	_dump() {
		console.log(`--- events registered AT emitter ${this.constructor.name}:`);
		Q.forEach(gsubs_, ([gevt, ghandler /* gonce */, , gopts, gemitter], sub_id) => {
			if (gemitter !== this) return;
			console.log(
				'\t#' + sub_id,
				'\t',
				Q.toString(gevt, -20),
				'\t',
				ghandler.constructor.name,
				'\t',
				gopts ? gopts.offxsel.constructor.name : '-',
				gemitter.constructor.name
			); // emitter
		});
		console.log(`--- events registered BY emitter ${this.constructor.name}:`);
		Q.forEach(gsubs_, ([gevt, ghandler /* gonce */, , gopts, gemitter], sub_id) => {
			if (!gopts || gopts.offxsel !== this) return;
			console.log(
				'\t#' + sub_id,
				'\t',
				Q.toString(gevt, -20),
				'\t',
				ghandler.constructor.name,
				'\t',
				gopts ? gopts.offxsel.constructor.name : '-',
				gemitter.constructor.name
			); // emitter
		});
	}
	static _gdump(evt) {
		console.log(`--- listeners for evt ${evt}:`);
		Q.forEach(gsubs_, ([gevt, ghandler /* gonce */, , gopts, gemitter], sub_id) => {
			if (evt && evt !== gevt) return;
			console.log(
				'\t#' + sub_id,
				'\t',
				Q.toString(gevt, -20),
				'\t',
				ghandler.constructor.name,
				'\t',
				gopts ? gopts.offxsel.constructor.name : '-\t',
				'\t',
				gemitter.constructor.name
			); // emitter
		});
	}

	hasListeners_with_offxsel() {
		for (const subid in gsubs_) {
			const [gevt, ghandler /* gonce */, , gopts, gemitter] = gsubs_[subid];
			if (this !== gemitter) continue;
			if (!gopts) continue;
			if (!gopts.offxsel) continue;
			Q.log(
				'?QEvents.hasListeners_with_offxsel',
				'\t#' + subid,
				'\t',
				Q.toString(gevt, -20),
				'\t',
				ghandler.constructor.name,
				'\t',
				gopts ? gopts.offxsel.constructor.name : '-\t',
				'\t',
				gemitter.constructor.name
			); // emitter
			return true;
		}
		return false;
	}

	static includesx(offxsel, evt) {
		for (const subid in gsubs_) {
			const [gevt /* ghandler */ /* gonce */, , , gopts /* gemitter */] = gsubs_[subid];
			if (gevt === evt && gopts && gopts.offxsel === offxsel) return true;
		}
		return false;
	}

	once(evt, listener, opts) {
		return this.on(evt, listener, opts, 1);
	}

	off(sub_id___or___evt) {
		// ---
		// remove *all* subs for a certain event
		// ---
		if (!Q.is.numericInt(sub_id___or___evt)) {
			const evt = sub_id___or___evt;
			const evt_subs = this._events[evt] || [];
			for (let j = evt_subs.length - 1; j >= 0; j--) this.off(evt_subs[j]);
			return this;
		}

		// ---
		// remove only a specific sub
		// ---
		const sub_id = parseInt(sub_id___or___evt);
		const sub = gsubs_[sub_id];
		assert(sub);

		// remove from global list
		delete gsubs_[sub_id];

		// remove from emitter's full list
		// todo rem assert( this._subs[sub_id]);
		// todo rem delete this._subs[ sub_id];

		// remove from emitter's "per evt" fast lookup list
		const evt = sub[0];
		const evt_subs = this._events[evt];
		const j = evt_subs.indexOf(sub_id);
		assert(j >= 0);
		evt_subs.splice(j, 1);
		if (!evt_subs.length) delete this._events[evt]; // remove evt if it has no more listeners

		return this; // allow queueing
	}

	/**
	 * synchronous dispatch event to all listeners
	 * @param evt:string - ctrl events are prefixed with 'c#'
	 * @param params[] - array of event params
	 */
	emit(evt, ...params) {
		const perf = Q.perfSilent(evt);
		try {
			const r = this.emitx(null, evt, ...params);
			perf(null, 'ok', Object.keys(r)); //just log 'ok' and the keys of r, reduce CPU load
			return r;
		} catch (err) {
			perf('QEvents.emit.fault', err, params);
			throw err;
		}
	}

	/**
	 * synchronous dispatch event to listeners ***** associated with <offx> only *****
	 * @param offx - association
	 * @param evt:string - ctrl events are prefixed with 'c#'
	 * @param params[] - array of event params
	 */
	// eslint-disable-next-line sonarjs/cognitive-complexity
	emitx(offxsel, evt, ...params) {
		assert(!!evt);

		// inform the hook
		if (this.hookBefore) {
			try {
				const evt_params = [evt, ...params];
				this.hookBefore.call(this, evt_params); // may modify the evt_params!
				evt = evt_params[0];
				params = evt_params.slice(1);
			} catch (err) {
				assert(false, err);
				this.emit('fault', Q.VERBOSE_ITF ? err.stack : err.message);
				return this;
			}
		}

		const evt_subs = this._events[evt];

		// todo turn off this logging ?
		// nobody listening to this event -> show warning
		if (!evt_subs && !this.hookBefore && !this.hookAfter) {
			if (evt.startsWith('db!')) return this; // suppress log output in certain cases
			if (evt.startsWith('db$')) return this;
			if (evt.startsWith('dbblob$')) return this;

			Q.log(`?${this.constructor.name} #${evt} - no listener`, JSON.stringify(params).substr(0, 150));
			return this; // allow queueing
		}

		// listeners found -> emit to them!
		const _once_sub_ids = []; // cleanup-list of listeners registered using .once()
		if (evt_subs)
			evt_subs.forEach(sub_id => {
				// dispatch to all listeners
				try {
					const [, /* gevt */ ghandler, gonce, gopts] = gsubs_[sub_id];
					const goffxsel = gopts ? gopts.offxsel : null;

					if (!offxsel || offxsel === goffxsel) {
						if (gonce) _once_sub_ids.push(sub_id);
						ghandler.call(this, ...params);
					}
				} catch (err) {
					Q.diagOnce('Q.emit.' + (err && (err.code || err.message)), err);
					this.emit('fault', Q.VERBOSE_ITF ? err.stack : err.message);
				}
			});

		// remove all once-subs found
		_once_sub_ids.forEach(this.off.bind(this));

		// inform the hook
		if (this.hookAfter) {
			try {
				this.hookAfter.call(this, [evt, ...params]);
			} catch (err) {
				assert(false, err);
				this.emit('fault', Q.VERBOSE_ITF ? err.stack : err.message);
				return this;
			}
		}

		return this; // allow queueing
	}

	_getsub(sub_id) {
		const sub = gsubs_[sub_id];
		assert(sub);
		return sub;
	}
	_fire(sub, evt, ...params) {
		const [sevt, shandler /*sonce*/ /* sopts*/, ,] = sub;
		if (sevt === evt) shandler.call(this, ...params);
	}
};

/**
 * EXAMPLE
 * 		const mtx= new Q.Mutex();
 * 		await mtx.runExclusive( async()=>{
 * 			...my function to protect...
 * 		});
 */
Q.Mutex = class {
	constructor(name = '(unnamed)') {
		this.name = name;
		this._locked = 0;
		this._chain = []; // list of waiting mutex locks
		this._perfs = []; // list pending call id's for timeout monitoring
	}
	get islocked() {
		return !!this._locked;
	}

	asy_lock() {
		// we are the only one -> resolve lock immediately!
		if (++this._locked === 1) {
			return; //b) this.unlock.bind(this); // returns Promise.resolve
		}
		// mutex already locked by someone else -> queue lock!
		return new Promise(resolve => {
			this._perfs.push(Q.perfSilent('mutex ' + this.name));
			this._chain.push(resolve);
		});
	}
	unlock() {
		--this._locked;
		// someone else waiting for mutex? -> resolve his lock!
		if (this._chain.length) {
			const perf = this._perfs.shift();
			const resolve = this._chain.shift();
			perf(null);
			resolve(); // b) resolve( this.unlock.bind(this));
		}
	}
	async runExclusive(asy_func) {
		await this.asy_lock();
		try {
			await asy_func();
		} finally {
			this.unlock();
		}
	}
};

/* ------------------------------------------------------------------
 * 		API PERFORMANCE MONITOR
 * ------------------------------------------------------------------
 */

Q.perfSlowLimit = Q.is_client ? 1000 : 500; // [ms] default limit for "SLOW" warnings

const perfTags_ = {}; // {tag}:{ pending:0, maxpending:0, count:0, errcnts:{}, ttotal:0, tmin:0x7fffffff, tmax:0};

/** send a noteable perf log to diag logging (default:console output)
 * +++ this function is intended for overwrite by some remote diag interface +++
 *
 * @param errOrSlow:null|<error-code>
 * @param ms:number
 * @param tag:string
 * @param replydata:object
 * @param reqdata:object
 */
Q.perfDiag = function (errOrSlow, ms, tag, replydata, reqdata) {
	if (errOrSlow) Q.log('!ERR', errOrSlow, ms + 'ms', tag, replydata, reqdata);
	else Q.log('?SLOW', ms + 'ms', tag, replydata, reqdata);
};

/** add test function to suppress diag log
 *
 * @param func:function( tag, err, replydata, reqdata):boolean - returns true if diag log is to be skipped
 */
const _perfDiagSkipFuncs = [];
Q.perfDiagSkip = function (func) {
	_perfDiagSkipFuncs.push(func);
};

/** add ignore test function - totally ignore a tag from perf analysis
 *
 * @param func:function( tag):boolean - returns true if tag is to be ignored
 */
const _perfIgnoreFuncs = [];
Q.perfIgnore = function (func) {
	_perfIgnoreFuncs.push(func);
};

/**
 * shorthand for Q.perf with silent flag set
 */
Q.perfSilent = function (tag, limit = null) {
	return Q.perf(tag, limit, true);
};

/**
 * @param tag:string - identification token for accumulation
 * @param limit:number - [ms] optional, defaults to Q.perfSlowLimit
 * 						logs a "SLOW" error if ok-response time exceeds this limit;
 * 						in case of error-response, the time limit is ignored and the error is logged "as is"
 * @param silent:bool - report this tag only in case of errors or if time limit exceeds (suppress reporting if everything is ok)
 * 							note: use Q.perfAutoLog({silentIgnore:true}) to turn off this behaviour for automatic logging
 * @result function(err:number|string,replydata:{}|null,requestdata:{}|null) - done function
 *              replydata and requestdata are handed over to Q.perfDiag (which is called in case of problems)
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
Q.perf = function (tag, limit = null, silent = false) {
	// check if tag shell be ignored
	// -> return a dummy (noop) function in this case
	const ignore = _perfIgnoreFuncs.some(func => func(tag));
	if (ignore) {
		return function () {};
	}

	if (!limit) limit = Q.perfSlowLimit;
	if (!perfTags_[tag])
		perfTags_[tag] = {
			pending: 0,
			maxpending: 0,
			count: 0,
			errcnts: {},
			slowcnts: 0,
			ttotal: 0,
			tmin: 0x7fffffff,
			tmax: 0
		};

	const t0 = Date.now();
	const p = perfTags_[tag];

	p.silent = silent;
	p.pending++;
	p.maxpending = Math.max(p.maxpending, p.pending);

	const perfEnd = function (err, replydata, reqdata) {
		const ms = Date.now() - t0;
		p.pending--;
		p.count++;
		p.ttotal += ms;
		p.tmax = Math.max(p.tmax, ms);
		p.tmin = Math.min(p.tmin, ms);

		const skip = _perfDiagSkipFuncs.some(func => func(tag, err, replydata, reqdata));

		if (err) {
			if (!p.errcnts[err]) p.errcnts[err] = 0;
			p.errcnts[err]++;
			if (!skip) Q.perfDiag(err, ms, tag, replydata, reqdata);
		} else if (ms > limit) {
			p.slowcnts++;
			if (!skip) Q.perfDiag(null, ms, tag, replydata, reqdata); // "slow" message
		}
	};

	let _watchdog = setTimeout(() => {
		_watchdog = null;
		perfEnd('never-returned');
	}, 100 * 1000);

	return function (err, replydata, reqdata) {
		if (_watchdog) {
			clearTimeout(_watchdog);
			_watchdog = null;
			perfEnd(err, replydata, reqdata);
		} else {
			Q.diagOnce('?performance-tag-already-watchdogged-' + tag, {
				err,
				replydata,
				reqdata
			});
		}
	};
};

/**
 * @return []:{ _tag, pending:0, maxpending:0, count:0, errcnts:{}, ttotal:0, tmin:0x7fffffff, tmax:0};
 *      ttotal, tmin, tmax - only valid if count > 0
 */
Q.perfRead = function () {
	return Q.objectToArray(perfTags_, '_tag');
};

/**
 *
 * @param silentTmax
 * @param silentIgnore
 * @return {{hiddenCount: number, items: []}}
 *      per item: [
			intro,                      // ok, !ERR=error, ?PWN=pending warning, ?SLW=slow
			stats,                      // number calls \t min..avg..max [ms]
			tag,                        // performance tag
			pendingWarn,                // number of pending warnings
			`SLOW=${p.slowcnts}`:'',    // number of slow warnings
			`\terrs: ${errs}` : ''      // list of errors seen
		];
 */
Q.perfReadFormatted = function (silentTmax, silentIgnore) {
	const list = Q.perfRead();

	// sort by slowest call
	list.sort((pa, pb) => {
		return pb.tmax - pa.tmax;
	});

	// generated formatted output
	const items = [];
	list.forEach(p => {
		const times = p.count
			? `${Q.toString(p.tmin, 5)}..${Q.toString(Math.round(p.ttotal / p.count), 5)}..${Q.toString(p.tmax, 5)}ms`
			: '';
		const errs = Object.entries(p.errcnts)
			.map(([k, v]) => `${k}=${v}`)
			.join(' ');
		const pendingWarn = p.pending > 2 ? `\tpending warning:${p.pending}/${p.maxpending}` : '';

		let intro = 'ok ';
		if (errs) intro = '!ERR';
		else if (pendingWarn) intro = '?PWN';
		else if (p.slowcnts) intro = '?SLW';

		if (!silentIgnore && p.silent && !errs && !pendingWarn) return; // skip ok items in silent mode
		if (p.tmax < silentTmax && !errs && !pendingWarn) return; // skip ok items with <1ms max. runtime

		const ln = [
			intro, // ok, !ERR=error, ?PWN=pending warning, ?SLW=slow
			`${Q.toString(p.count, 3)}\t${times}`, // number calls \t min..avg..max [ms]
			p._tag, // performance tag
			pendingWarn, // number of pending warnings
			p.slowcnts ? `SLOW=${p.slowcnts}` : '', // number of slow warnings
			errs ? `\terrs: ${errs}` : '' // list of errors seen
		];

		items.push(ln);
	});
	return { items, hiddenCount: list.length - items.length };
};

Q.perfToDiagOnce = function () {
	const list = Q.perfRead();

	list.forEach(p => {
		const errcodes = Object.keys(p.errcnts);

		let intro;
		if (errcodes.length) intro = `!perf.error.${errcodes.join('.')}`;
		else if (p.maxpending > 2) intro = '?perf.pending';
		else if (p.slowcnts) intro = '?perf.slow';

		if (!intro) return; // skip all ok items

		Q.diagOnce(`${intro}.${p._tag}`, {
			...p,
			tavg: p.count ? Math.round(p.ttotal / p.count) : 0
		});
	});
};

/** enable/disable automatic logging every 60s
 *
 * @param opts:boolean|object
 *      falsy - disable automatic logging
 *      truthy - enable automatic logging /w default parameters
 *      {} - enable automatic logging, with these optional parameters:
 *          .silentIgnore:boolean - report ok items even if they are marked as silent
 *          .silentTmax:number - [ms] do not report ok items with < N ms runtime
 */
let _perfAutoLogOptions = null;

Q.perfAutoLog = function (opts) {
	if (!opts) _perfAutoLogOptions = null;

	if (!Q.is.object(opts)) opts = {};
	opts.silentIgnore = !!opts.silentIgnore;
	if (!Q.is.numberInt(opts.silentTmax)) opts.silentTmax = 2;

	_perfAutoLogOptions = opts;
};
function _perfAutoLogTask() {
	if (!Q._perfAutoLogOptions) return;

	const { items, hiddenCount } = Q.perfReadFormatted(_perfAutoLogOptions.silentTmax, _perfAutoLogOptions.silentIgnore);

	Q.log('~=== PERFORMANCE REPORT ===', Q.stampToString());
	for (const ln of items) Q.log(...ln);
	Q.log(`~=== /PERFORMANCE REPORT === ${hiddenCount} hidden items`);
}
setInterval(_perfAutoLogTask, 60000);

Q.perfAutoLog(true);

Q.perfTimer = function (caption) {
	const t0 = Date.now();
	return function () {
		const ms = Date.now() - t0;
		Q.log(caption, ms, 'ms');
	};
};

/* ------------------------------------------------------------------
 * extend JSON with easy going API fetch method
 * ------------------------------------------------------------------
 */

/**
 * execute REST API access with JSON results (and optional JSON parameters)
 *
 * @param auth
 *            null - no authorization
 *            :string -  authorization header, e.g. 'Basic <username>:<pwd>'
 *            []:string - basic auth [<username>,<pwd>]
 * @param method:string - GET, PUT, POST, DEL(ELETE)
 * @param url:string -  "https://<domain>/<sub>"
 * @param queryparams:null|{} - any object; sent as url params with method 'get', or as body otherwise
 * @param trusted:bool - true ignores invalid / expired SSL certificates
 * @return {} - data returned by reply
 * @throws {code:httpStatus, info:{details...}}
 * 		999 if reply body has malformed json
 */
if (!JSON.asy_fetch)
	// eslint-disable-next-line sonarjs/cognitive-complexity
	JSON.asy_fetch = async function (auth, method, url, queryparams = null, headers = {}, trusted = false) {
		if (Q.is.array(auth) && auth.length === 2) auth = 'Basic ' + Q.btoa(auth[0] + ':' + auth[1]);
		method = (method || 'GET').toUpperCase();
		if (method === 'DEL') method === 'DELETE';

		assert(auth === null || Q.is.stringNotEmpty(auth));
		assert(['GET', 'PUT', 'POST', 'DELETE'].includes(method));
		assert(Q.is.stringNotEmpty(url));
		assert(queryparams === null || Q.is.objectNotEmpty(queryparams));

		const opts = {
			rejectUnauthorized: !trusted,
			method: method,
			headers: { ...headers }
		};

		opts.headers['Content-Type'] = 'application/json';

		if (auth) opts.headers['Authorization'] = auth;

		let queryString = '',
			bodyString = '';

		if (queryparams) {
			if (method === 'GET')
				// node: querystring.stringify(params);
				// jquery: $.param( params)
				queryString =
					'?' +
					Object.keys(queryparams)
						.map(key => {
							return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(queryparams[key]));
						})
						.join('&');
			else bodyString = JSON.stringify(queryparams);
		}

		const perf = Q.perf(`${method} ${url}`);

		assert(Q._JSON_fetch);
		return new Promise((resolve, reject) => {
			Q._JSON_fetch(opts, url, queryString, bodyString, (httpStatus, data) => {
				// any httpStatus outside 200..204 signals error condition
				if (httpStatus < 200 || httpStatus > 204) {
					perf(httpStatus, data, { opts: opts, params: queryparams });

					return reject({
						code: httpStatus,
						info: {
							url: method + ' ' + url,
							query: queryString,
							opts: opts,
							[httpStatus === 970 ? 'err' : 'replyBody']: data
						}
					});
				}

				try {
					data = JSON.parse(data || null); // treat "" and undefined as null (to increase server compatibility)
					perf(null, 'ok', { opts: opts, params: queryparams }); //just log 'ok'
					return resolve(data);
				} catch (err) {
					perf(999, data, { opts: opts, params: queryparams });

					reject({
						// catch any JSON.parse error
						code: 999,
						info: {
							err: err,
							url: method + ' ' + url,
							query: queryString,
							opts: opts,
							replyBody: data
						}
					});
				}
			});
		});
	};

/**
 * Simple async queue
 *
 * @example
 * const queue = new Q.Queue(async task => {
 *   //do something with the task object
 * })
 * //push a task object to the queue
 * queue.push(task)
 *
 * await queue.push(task) //you can await the push to proceed after the task is processed
 */
Q.Queue = class {
	/**
	 * @param worker{Function} - An function for processing a queued task.
	 * @param concurrency{Number} - An integer for                     determining how many worker functions should be run in parallel (default 1)
	 */
	constructor(worker, concurrency = 1) {
		this.queue = [];
		this.processing = false;
		assert(Q.is.function(worker));
		this.functionToProcess = worker;
		this._pause = false;
		this._concurrency = concurrency || 1;
		this._pendingTasks = 0;
	}

	/**
	 * get the current length of the queue
	 * @return {number}
	 */
	get length() {
		return this.queue.length;
	}

	get __canProcess() {
		return this._pendingTasks < this._concurrency;
	}

	/**
	 * Promise resolves when the queue is drained
	 * @return {Promise<unknown>}
	 */
	get drained() {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async resolve => {
			while (this.length) await Q.asyncTimeout(5);
			return resolve();
		});
	}

	async __taskRunner(nextEntry) {
		this._pendingTasks++;
		try {
			const resp = await this.functionToProcess(nextEntry.task);
			nextEntry.resolve(resp);
		} catch (e) {
			nextEntry.reject(e);
		} finally {
			this._pendingTasks--;
			this.__process();
		}
	}

	async __process() {
		if (!this.__canProcess || this._pause) return;
		if (!this.queue.length) return;
		const nextEntry = this.queue.shift();
		this.__taskRunner(nextEntry);
		if (this.queue.length) this.__process();
	}

	__addTask(task, unshift = false) {
		return new Promise((resolve, reject) => {
			if (unshift) this.queue.unshift({ resolve, reject, task });
			else this.queue.push({ resolve, reject, task });
			if (!this._pause) this.__process();
		});
	}
	/**
	 * Push a new task to the queue
	 * @param task
	 */
	push(task) {
		return this.__addTask(task);
	}
	/**
	 * Push a new task to beginning of the queue
	 * @param task
	 */
	unshift(task) {
		return this.__addTask(task, true);
	}
	pause() {
		this._pause = true;
	}
	resume() {
		this._pause = false;
		this.__process();
	}
};
