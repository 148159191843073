// Styles
import 'vuetify/styles';
// Vuetify
import { i18n } from './i18n.mjs';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import { defaultConfiguration } from '@mt360/client-core/plugins/vuetfiy.mjs';

//https://next.vuetifyjs.com/en/features/theme/#customizing
export default createVuetify({
	locale: {
		adapter: createVueI18nAdapter({ i18n, useI18n })
	},
	...defaultConfiguration
});
