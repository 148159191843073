import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import { useCmsStore } from '@/stores/cms';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'Home',
			component: HomeView
		},
		{
			path: '/articles/:articleId',
			component: () => import('@/views/ArticleDetailView.vue')
		},
		{
			path: '/apidocumentation',
			component: () => import('@/views/ApiDocView.vue')
		},
		{
			path: '/dopcalculator',
			component: () => import('@/views/DopCalculator.vue')
		}
	],
	scrollBehavior(to) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth'
			};
		}
	}
});

router.beforeEach(async to => {
	//root path id /apps
	if (to.path === '/') {
		//get the cmsStore from pinia
		const cmsStore = useCmsStore();
		await cmsStore.getMenuItems();

		// use first menu item which link to an article as front article
		const frontArticle = cmsStore.menuItems.data.filter(item => item.article.length > 0 && !item.parent_item)[0]
			.article[0];

		return { path: `/articles/${frontArticle}` };
	}
});
export default router;
