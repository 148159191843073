import { defineStore } from 'pinia';
import Q from '@mt360/q';

const API_HOST = import.meta.env.VITE_MT360_API;

function getRedirectParams() {
	return new URLSearchParams({ redirectPath: window.location.href }).toString();
}

export const useUserData = defineStore({
	id: 'userdata',
	state: () => ({
		_registrationPending: false,
		_registrationResolve: {
			resolve: () => {},
			reject: () => {}
		},
		_diagInit: false,
		user: Object,
		_userRedirectUrl: '',
		_organization: Object,
		_theme: 'light',
		_paginationLimit: 25,
		_planRestrictions: {
			deviceLimit: 1 //default value - will be overwritten by fetchPlanRestrictions
		},
		_validateAuthPending: null
	}),
	getters: {
		loginPending(state) {
			return !Object.keys(state.user).length;
		},
		loginRedirectUrl(state) {
			return state._userRedirectUrl;
		},
		bearerToken() {
			return null; //not needed due to implicit auth with cookie session!
		},
		diagInit(state) {
			return state._diagInit;
		},
		darkTheme(state) {
			//todo eg. follow system
			return state._theme;
		},
		paginationLimit(state) {
			return state._paginationLimit;
		},
		organization(state) {
			return state._organization;
		},

		//planRestrictions
		getMaxDeviceLimit(state) {
			return state._planRestrictions?.deviceLimit || 5;
		},
		getDopWarnLimits(state) {
			return state._planRestrictions?.dopWarnLimits || [10000];
		}
	},
	actions: {
		async fetchOrganizationInformations() {
			//fetch the users organization data
			this._organization = await JSON.asy_fetch(
				this.bearerToken,
				'GET',
				`${API_HOST}/1/organizations/${this.user.organizationId}`
			);

			//fetch the plan restrictions of the current organization to
			await this.fetchPlanRestrictions();
		},
		async upadteOrganizationInformation(updatedOrganization) {
			await JSON.asy_fetch(
				this.bearerToken,
				'PUT',
				`${API_HOST}/1/organizations/${this.user.organizationId}`,
				updatedOrganization
			);
			//automatically update the cache if the api request was successful
			this._organization = Object.assign(this._organization, updatedOrganization);
		},

		async _fetchAuthInformation() {
			try {
				this.user = {};
				const auth = await JSON.asy_fetch(null, 'GET', `${API_HOST}/auth?${getRedirectParams()}`);
				if (Object.hasOwn(auth, 'redirect')) {
					this._userRedirectUrl = auth.redirect;
				} else if (Object.hasOwn(auth, 'id')) {
					this.user = auth;
					await this.initializeDiagHandler();
					await this.fetchOrganizationInformations();
				}
			} catch (e) {
				Q.log('!validate.auth', e);
			} finally {
				this._validateAuthPending = null;
			}
		},

		async validateAuthentication() {
			if (this._validateAuthPending) return this._validateAuthPending;
			return (this._validateAuthPending = this._fetchAuthInformation());
		},

		async _performUserRegistration() {
			if (!Object.keys(this.user).length && !this._userRedirectUrl) {
				await this.validateAuthentication();
			}
			if (this._userRedirectUrl) {
				const redirectUrl = this._userRedirectUrl;
				this._userRedirectUrl = '';
				window.open(redirectUrl, '_self');
			}
		},

		async registerCurrentUser() {
			if (this._registrationPending) return this._registrationPending;
			//already fetched userdata
			if (Q.is.objectNotEmpty(this.user)) {
				return;
			}

			//initialize the promise that waits for the registration
			this._registrationPending = new Promise((resolve, reject) => {
				this._performUserRegistration()
					.then(resolve)
					.catch(reject)
					.finally(() => {
						this._registrationPending = false;
					});
			});
		},

		async fetchPlanRestrictions() {
			this._planRestrictions = await JSON.asy_fetch(
				this.bearerToken,
				'GET',
				`${API_HOST}/1/system/settings/planrestrictions`
			);
		},
		/**
		 * Initilizes the Q.diagOnce forwarding
		 * @returns {Promise<void>}
		 */
		async initializeDiagHandler() {
			if (this._diagInit) return;
			//initialize diagnose function - with forwarding to the backend
			Q.diagInit(async (topic, info = '') => {
				//          stamp, title, source, info
				try {
					//log the diagnose data to the console
					Q.log(topic, info);
					//forward diag information to the server
					await JSON.asy_fetch(this.bearerToken, 'POST', `${API_HOST}/1/diag-append`, {
						stamp: new Date().toISOString(),
						topic,
						source: 'browser', //todo current user, app, session info ...
						info: JSON.stringify(info)
					});
				} catch (e) {
					Q.log(`!Q.diagForward`, e);
				}
			});
			this._diagInit = true;
			Q.log('~DiagHandler.initialized');
		},
		signOut() {
			window.open(`${API_HOST}/logout`, '_self');
		},
		async changeTheme() {
			this._theme = this._theme === 'light' ? 'dark' : 'light';
		}
	}
});
