<template lang="pug">
v-app.w-auto.h-auto
	BaseToolbar
		v-app-bar-nav-icon(v-if="mobile", variant="text", @click.stop="drawer = !drawer")
		template(v-slot:content)
			LanguageSwitch
			InfoPopup
			AccountPopup

	v-navigation-drawer(v-if="mobile", v-model="drawer", temporary)
		nav-drawer-article
	v-main
		v-container.pa-0.px-md-3.py-md-6.d-flex.flex-row.justify-center(fluid)
			v-sheet.mr-6.pb-4(v-if="!mobile", min-height="85vh", height="100%", min-width="296px", max-width="296px")
				nav-drawer-article
			v-sheet(min-height="85vh", height="100%", width="980px", max-width="980px")
				router-view
				BackToTopButton
			template(v-if="showSubNavigation")
				v-sheet.sticky-menu.ml-6.pb-2(
					v-if="contentItems.length",
					min-height="144px",
					min-width="240px",
					max-width="240px"
				)
					nav-drawer-content
				.ml-6(v-else, style="min-width: 240px")
		ReloadPrompt
</template>
<script>
import { useDisplay } from 'vuetify';
import BaseToolbar from '@mt360/client-core/components/BaseToolbar.vue';
import LanguageSwitch from '@mt360/client-core/components/LanguageSwitch.vue';
import AccountPopup from '@mt360/client-core/components/AccountPopup.vue';
import NavDrawerArticle from '@/components/NavDrawerArticle.vue';
import NavDrawerContent from '@/components/NavDrawerContent.vue';
import { useCmsStore } from '@/stores/cms.mjs';
import { storeToRefs } from 'pinia/dist/pinia.esm-browser';
import { computed } from 'vue';
import BackToTopButton from '@mt360/client-core/components/BackToTopButton.vue';
import InfoPopup from '@mt360/client-core/components/InfoPopup.vue';
import ReloadPrompt from '@mt360/client-core/components/ReloadPrompt.vue';

export default {
	name: 'App',
	components: {
		BaseToolbar,
		LanguageSwitch,
		NavDrawerArticle,
		NavDrawerContent,
		BackToTopButton,
		InfoPopup,
		ReloadPrompt,
		AccountPopup
	},
	setup() {
		const cmsStore = useCmsStore();
		const { contentItems } = storeToRefs(cmsStore);
		const { name, mobile } = useDisplay();

		const showSubNavigation = computed(() => {
			// name is reactive and
			// must use .value
			switch (name.value) {
				case 'xs':
					return false;
				case 'sm':
					return false;
				case 'md':
					return false;
				case 'lg':
					return true;
				case 'xl':
					return true;
				case 'xxl':
					return true;
				default:
					return true;
			}
		});

		//initialize user authentication
		return { showSubNavigation, mobile, contentItems };
	},
	data: () => ({
		drawer: null
	})
};
</script>

<style lang="scss">
.sticky-menu {
	position: sticky;
	top: 88px;
	z-index: 1;
	height: fit-content;
}

.v-card-title {
	padding-left: 0 !important;
}

.swagger-ui {
	// hide swagger api title
	.information-container {
		display: none;
	}
}
</style>
