export default {
	BasePagination: {
		texts: {
			total: 'Gesamt',
			page: 'Seite',
			displaying: 'angezeigt {count}',
			of: 'von'
		}
	},

	//general dialog texts
	dialogs: {
		cancel: 'Abbrechen',
		confirm: 'Bestätigen',
		close: 'Schließen'
	},

	//AccountPopup.vue
	accountPopup: {
		actions: {
			manageAccount: 'Verwalte dein Konto',
			signIn: 'Anmelden',
			signOut: 'Abmelden',
			terms: 'Nutzungsbedingungen',
			privacy: 'Datenschutzerklärung'
		}
	},

	//LanguageSwitch.vue
	languageSwitch: {
		translations: 'Übersetzungen',
		en: 'Englisch',
		de: 'Deutsch'
	},

	//BackToTopButton.vue
	backToTop: {
		actions: {
			top: 'Oben'
		}
	},

	//InfoPopup.vue
	infoPopup: {
		actions: {
			giveFeedback: 'Feedback geben'
		}
	},

	//ReloadPrompt
	reloadPrompt: {
		texts: {
			newContent1: 'Neuer Inhalt verfügbar. Drücke',
			newContent2: 'zum Updaten.'
		},
		actions: {
			refresh: 'Refresh',
			close: 'Schließen'
		}
	},

	// Notification Popup
	notificationPopup: {
		texts: {
			title: 'Benachrichtigungen',
			noNotifications: 'Es sind noch keine Benachrichtigungen verfügbar.'
		},
		actions: {
			markAllAsRead: 'Alle als gelesen markieren'
		},
		tooltips: {
			markAsRead: 'Als gelesen markieren',
			markAsUnread: 'Als ungelesen markieren'
		}
	}
};
