import { createI18n } from 'vue-i18n';
//see https://vue-i18n.intlify.dev/guide/essentials/syntax.html for help
import datetimeFormats from '@mt360/client-core/locales/dateTimeFormats.mjs';
import enCore from '@mt360/client-core/locales/en.mjs';
import deCore from '@mt360/client-core/locales/de.mjs';
import en from '../locales/en.mjs';
import de from '../locales/de.mjs';

export const i18n = createI18n({
	legacy: false,
	//get browser language
	//locale: navigator.language.split('-')[0],
	locale: 'en',
	fallbackLocale: 'en',
	globalInjection: true,
	datetimeFormats,
	messages: {
		en: { ...enCore, ...en },
		de: { ...deCore, ...de }
	}
});
