<template lang="pug">
v-menu(v-if="showInfoButton", v-model="menu", location="bottom end")
	template(v-slot:activator="{ props }")
		v-btn.icon-filled.ml-2(v-bind="props", icon="help")
	v-list(min-width="145px", lines="one")
		v-list-item(:title="$t(`infoPopup.actions.giveFeedback`)", rounded="none", @click="giveFeedback")
</template>

<script>
export default {
	name: 'InfoPopup',
	data() {
		return {
			menu: false,
			collectorTriggerFunction: null
		};
	},
	computed: {
		showInfoButton() {
			return !!this.collectorScript;
		},
		collectorScript() {
			return import.meta.env.VITE_JIRA_ISSUE_COLLECTOR || null;
		}
	},
	mounted() {
		//check if a collector script is defined
		if (!this.collectorScript) return;

		//init handler for jira issue collector
		window.ATL_JQ_PAGE_PROPS = {
			triggerFunction: showCollectorDialog => {
				this.collectorTriggerFunction = showCollectorDialog;
			}
		};

		//add the collector script to the document
		const script = document.createElement('script');
		script.setAttribute('src', this.collectorScript);
		document.head.appendChild(script);
	},
	methods: {
		giveFeedback() {
			if (this.collectorTriggerFunction) this.collectorTriggerFunction();
		}
	}
};
</script>
