import '@mt360/client-core/core.mjs';
import '@mt360/q';
import '@mt360/q/browser.mjs';

import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from 'pinia';
import { i18n } from './plugins/i18n.mjs';
import App from './App.vue';
import router from './plugins/router.mjs';
import vuetify from './plugins/vuetify.mjs';
import AnchorHeadline from './components/AnchorHeadline.vue';
import HighlightJsHeader from './components/HighlightJsHeader.vue';
import { VBtn, VIcon } from 'vuetify/components';
import BaseTooltip from '@mt360/client-core/directives/BaseTooltip.vue';
import BaseCard from '@mt360/client-core/components/BaseCard.vue';
import { loadFonts } from '@mt360/client-core/plugins/webfontloader.mjs';
import Maska from 'maska';
loadFonts();

import BaseView from '@mt360/client-core/components/BaseView.vue';
import BaseTreeMenu from '@mt360/client-core/components/BaseTreeMenu.vue';

createApp(App)
	.use(i18n)
	.use(router)
	.use(vuetify)
	.use(Maska)
	.directive('tooltip', BaseTooltip)
	.component('BaseView', BaseView)
	.component('BaseTreeMenu', BaseTreeMenu)
	.component('BaseCard', BaseCard)
	.component('AnchorHeadline', AnchorHeadline)
	.component('HighlightJsHeader', HighlightJsHeader)
	.component('VBtn', VBtn)
	.component('VIcon', VIcon)
	.use(createPinia())
	.mount('#app');
