export default {
	//vuetify translation:
	$vuetify: {
		//sample:
		dataIterator: {
			rowsPerPageText: 'Items per page:',
			pageText: '{0}-{1} of {2}'
		}
	},

	//end vuetify translations

	//general dialog texts
	dialogs: {
		cancel: 'Abbrechen'
	},

	navigation: {
		headers: {
			docs: 'Dokumentation',
			articles: 'Artikel',
			content: 'Inhalt'
		}
	},

	// ApiDocView.vue
	apiDocumentation: {
		title: 'Playground'
	},

	// DopCalculator.vue
	dopCalculator: {
		title: 'DOP-Rechner',
		intro:
			'Dieser Rechner ist eine vereinfachte Version und berücksichtigt beispielsweise keine Datenoperationen, die über API durchgeführt werden.',
		introPackages: 'Nachfolgend finden sie eine Übersicht, wie viele Tage Sie jeweiligen Dops-Paket auskommen.',
		texts: {
			devices: 'Geräte',
			dataPoints: 'Datenpunkte',
			recordInterval: 'Aufzeichnungsintervall',
			dopPerDay: 'Datenoperationen pro Tag',
			dop: 'Datenoperationen',
			minutes: 'Minuten',
			dopPackages: 'DOP-Pakete',
			day: 'Tag',
			days: 'Tage'
		}
	},

	// AnchorHeadline.vue
	anchorHeadline: {
		tooltips: {
			copyLink: 'Link kopieren',
			linkCopied: 'Link kopiert'
		}
	},

	// HighlightJsHeader.vue
	HighlightJsHeader: {
		copyCode: 'Code kopieren',
		copied: 'Kopiert!'
	}
};
