<template lang="pug">
.px-4.py-2.caption.highlight-js-header
	span.label {{ language }}
	button.copy-button(@click="copy2Clipboard()")
		v-icon(:icon="copyIcon")
		span.text-xs.pl-1 {{ copyText }}
</template>

<script>
import Q from '@mt360/q';

export default {
	name: 'HighlightJsHeader',
	data() {
		return {
			copyIcon: 'content_copy',
			copyText: this.$t('HighlightJsHeader.copyCode')
		};
	},
	props: {
		codeBlockId: {
			type: String,
			default: ''
		},
		language: {
			type: String,
			default: ''
		}
	},
	computed: {},
	methods: {
		async copy2Clipboard() {
			try {
				const codeElement = document.getElementById(this.codeBlockId);
				await navigator.clipboard.writeText(codeElement.textContent);

				this.copyText = this.$t('HighlightJsHeader.copied');
				this.copyIcon = 'done';

				// change text
				setTimeout(() => {
					this.copyText = this.$t('HighlightJsHeader.copyCode');
					this.copyIcon = 'content_copy';
				}, 1000);
			} catch (err) {
				Q.log(`copy2Clipboard: Could not copy code`, err);
			}
		}
	}
};
</script>
<style lang="scss">
@import '@mt360/client-core/styles/variables';

.label {
	font-family: monospace;
}

.copy-button {
	float: right;
}

.highlight-js-header {
	background-color: $neutral30;
	color: $neutral70;
	position: sticky;
	left: 0;
}
</style>
