<template lang="pug">
v-list(density="compact", nav)
	BaseTreeMenuItem(v-for="item in items", :item="item")
</template>

<script>
import BaseTreeMenuItem from './BaseTreeMenuItem.vue';

export default {
	components: {
		BaseTreeMenuItem
	},
	props: ['items'],
	data() {
		return {};
	}
};
</script>
