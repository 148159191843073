import { aliases, md } from 'vuetify/iconsets/md';

const mt360Light = {
	dark: false,
	colors: {
		background: '#F2F0F4',
		surface: '#FFFFFF',
		primary: '#0F50E2',
		secondary: '#4459a9',
		tertiary: '#fbe411',
		info: '#1050E2',
		'info-container': '#DCE1FF',
		outline: '#767680',

		warning: '#6B5F00',
		'warning-container': '#FFF393',
		error: '#BA1A1A',
		'error-container': '#FFDAD6',
		error50: '#DE3730',
		success: '#416900',
		'success-container': '#F9FFE3',
		//custom colors
		'error-custom': '#E41B70',
		'warning-custom': '#DDC800',
		'success-custom': '#8EDB00',
		'secondary-container': '#424659',
		'dialog-surface': '#FEFBFF',
		neutral95: '#F2F0F4'
	}
};

export const defaultConfiguration = {
	//blueprint: md3,

	// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
	defaults: {
		global: {
			//ripple: false,
		},
		VBtn: {
			rounded: 'pill'
		},
		VSheet: {
			rounded: 'lg'
		},
		VDialog: {
			scrollStrategy: 'reposition'
		},
		VTextField: {
			variant: 'outlined',
			color: 'primary'
		},
		VTextarea: {
			variant: 'outlined',
			color: 'primary'
		},
		VSnackbar: {
			color: 'secondary-container',
			rounded: 'lg'
		},
		VSelect: {
			rounded: 'lg',
			variant: 'outlined'
		}
	},

	icons: {
		defaultSet: 'md',
		aliases,
		sets: {
			md
		}
	},
	display: {
		mobileBreakpoint: 'lg'
	},
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				dark: false,
				colors: { ...mt360Light.colors }
			}
		}
	}
};
