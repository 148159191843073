<template lang="pug">
.placeholder(:class="{ show: placeholderVisible || showButton }", v-scroll="onScroll", v-resize="onResize")
v-snackbar.back-to-top(
	:model-value="showButton",
	:style="backToTopPosition",
	location-strategy="connected",
	timeout="-1",
	variant="text"
)
	v-row.justify-center.align-center(style="height: 96px")
		v-btn.mb-6(prepend-icon="arrow_upward", size="x-large", rounded="lg", @click="scrollToTop") {{ $t('backToTop.actions.top') }}
</template>

<script>
import { useComponentStore } from '../stores/components.mjs';
import { storeToRefs } from 'pinia';
export default {
	name: 'BackToTopButton',
	setup() {
		const componentStore = useComponentStore();
		const { mainContentCenter, backToTopVisible } = storeToRefs(componentStore);
		return { componentStore, showButton: backToTopVisible, mainContentCenter };
	},
	data: () => ({
		placeholderVisible: false
	}),
	computed: {
		backToTopPosition() {
			if (!this.mainContentCenter) return {};
			return {
				left: this.mainContentCenter,
				transform: 'translateX(-50%)',
				opacity: 1
			};
		}
	},
	mounted() {
		this.componentStore.setMainContent(this.$parent);
	},
	methods: {
		validatePlaceholder() {
			const { height } = this.$parent.$el.getBoundingClientRect();
			let parentHeight = height;
			if (this.placeholderVisible) {
				parentHeight -= 68;
			}
			this.placeholderVisible = window.innerHeight + 200 < parentHeight;
		},
		onScroll() {
			this.setBackToTop = this.componentStore.setBackToTop(window.scrollY >= 200);
			this.validatePlaceholder();
		},
		onResize() {
			this.componentStore.setMainContent(this.$parent);
			this.validatePlaceholder();
		},
		scrollToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
};
</script>

<style lang="scss" scoped>
.back-to-top {
	/* stylelint-disable-next-line */
	.v-snackbar__wrapper {
		margin: 0 !important;
		padding: 16px !important;
	}

	.v-btn {
		background: linear-gradient(0deg, rgb(15 80 226 / 8%), rgb(15 80 226 / 8%)), #fefbff;
	}
}

.placeholder {
	height: 0;
	transition: all 0.3s ease;

	&.show {
		height: 68px;
	}
}
</style>
