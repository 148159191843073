import { defineStore } from 'pinia';

export const useComponentStore = defineStore({
	id: 'components',
	state: () => ({
		_mainContentBoundings: { x: 0, width: 0 },
		_backToTop: false
	}),
	getters: {
		mainContentCenter(state) {
			const rect = state._mainContentBoundings;
			return `${(rect.x + rect.width / 2).toFixed(0)}px`;
		},
		backToTopVisible(state) {
			return state._backToTop;
		}
	},
	actions: {
		setMainContent(component) {
			const { top, right, bottom, left, width, height, x, y } = component.$el.getBoundingClientRect();
			Object.assign(this._mainContentBoundings, {
				top,
				right,
				bottom,
				left,
				width,
				height,
				x,
				y
			});
		},
		setBackToTop(visible) {
			return (this._backToTop = visible);
		}
	}
});
