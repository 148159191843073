export default {
	BasePagination: {
		texts: {
			total: 'Total',
			page: 'Page',
			displaying: 'displaying {count}',
			of: 'of'
		}
	},

	//general dialog texts
	dialogs: {
		cancel: 'Cancel',
		confirm: 'Confirm',
		close: 'Close'
	},

	//AccountPopup.vue
	accountPopup: {
		actions: {
			manageAccount: 'Manage your account',
			signIn: 'Sign In',
			signOut: 'Sign Out',
			terms: 'Terms',
			privacy: 'Privacy'
		}
	},

	//LanguageSwitch.vue
	languageSwitch: {
		translations: 'Translations',
		en: 'English',
		de: 'German'
	},

	//BackToTopButton.vue
	backToTop: {
		actions: {
			top: 'Top'
		}
	},

	//InfoPopup.vue
	infoPopup: {
		actions: {
			giveFeedback: 'Give feedback'
		}
	},

	//ReloadPrompt
	reloadPrompt: {
		texts: {
			newContent1: 'New content is available. Click',
			newContent2: 'to update.'
		},
		actions: {
			refresh: 'Refresh',
			close: 'Close'
		}
	},

	// Notification Popup
	notificationPopup: {
		texts: {
			title: 'Notifications',
			noNotifications: "You don't have any notificaions yet."
		},
		actions: {
			markAllAsRead: 'Mark all as read'
		},
		tooltips: {
			markAsRead: 'Mark as read',
			markAsUnread: 'Mark as unread'
		}
	}
};
