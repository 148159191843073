export default {
	utc: {
		yearMonth: {
			month: 'long',
			year: 'numeric',
			timeZone: 'UTC'
		},
		short: {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
			timeZone: 'UTC'
		}
	},
	en: {
		yearMonth: {
			month: 'long',
			year: 'numeric'
		},
		short: {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			weekday: 'short',
			hour: 'numeric',
			minute: 'numeric'
		},
		dayTime: {
			weekday: 'short',
			day: 'numeric',
			month: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		},
		hourMinute: {
			hour: 'numeric',
			minute: 'numeric'
		}
	},
	de: {
		yearMonth: {
			month: 'long',
			year: 'numeric'
		},
		short: {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		},
		long: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			weekday: 'short',
			hour: 'numeric',
			minute: 'numeric'
		},
		dayTime: {
			weekday: 'short',
			day: 'numeric',
			month: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		},
		hourMinute: {
			hour: 'numeric',
			minute: 'numeric'
		}
	}
};
