<template lang="pug">
v-fade-transition(leave-absolute)
	v-btn.mx-2(
		v-show="!userAuthenticated && !loading",
		color="primary",
		variant="elevated",
		@click="userStore.registerCurrentUser()"
	) {{ $t('accountPopup.actions.signIn') }}
v-menu(v-model="menu", location="bottom end", close-on-content-click)
	template(v-slot:activator="{ props }")
		v-fade-transition(leave-absolute, hide-on-leave)
			v-btn.icon-filled.ml-2(
				v-show="userAuthenticated || loading",
				v-bind="props",
				icon="account_circle",
				:loading="loading"
			)
	v-list.px-4.pb-0(min-width="344", min-height="200")
		v-list-item.icon-filled(prepend-icon="person")
			v-list-item-title {{ user.firstName }} {{ user.lastName }}
			v-list-item-subtitle {{ user.email }}
		v-list-item
			template(v-slot:prepend)
				.mr-14
			v-list-item-action
				v-btn(variant="outlined", size="small", :href="user.manageUrl", target="_blank") {{ $t('accountPopup.actions.manageAccount') }}
		v-divider
		v-list-item(prepend-icon="logout", @click="userStore.signOut()", rounded="false")
			v-list-item-title {{ $t('accountPopup.actions.signOut') }}
		v-divider
		v-list-item.py-1(density="compact")
			v-list-item-action.justify-center
				v-btn.px-1.mr-1.caption(
					size="small",
					variant="text",
					rounded="small",
					href="https://docs.microtronics.one/articles/44",
					target="_blank"
				) {{ $t('accountPopup.actions.privacy') }}
				.caption.mx-1 •
				v-btn.px-1.ml-1.caption(
					size="small",
					variant="text",
					rounded="small",
					href="https://docs.microtronics.one/articles/43",
					target="_blank"
				) {{ $t('accountPopup.actions.terms') }}
iframe.d-none(ref="dummyLogin", sandbox="allow-same-origin allow-scripts")
</template>

<script>
/* global cookieStore */

import { useUserData } from '@mt360/client-core/stores/userdata.mjs';
import { storeToRefs } from 'pinia';
import Q from '@mt360/q';

/**
 * Listen for cookie changes
 * @param callback{function}
 * @param interval{number}
 */
function listenCookieChange(callback, interval = 1000) {
	let lastCookie = document.cookie;
	setInterval(() => {
		const cookie = document.cookie;
		if (cookie !== lastCookie) {
			try {
				callback({ oldValue: lastCookie, newValue: cookie });
			} finally {
				lastCookie = cookie;
			}
		}
	}, interval);
}
export default {
	name: 'AccountPopup',
	setup() {
		const userStore = useUserData();
		const { user, darkTheme } = storeToRefs(userStore);
		return { userStore, user, darkTheme };
	},
	data() {
		return {
			menu: false,
			loading: true
		};
	},
	computed: {
		userAuthenticated() {
			return Object.keys(this.user).length;
		}
	},
	async mounted() {
		await this.validateUser();
		this.getLoginSessionWithIframe();
		this.listenForCookieChange();
	},
	methods: {
		async validateUser() {
			this.loading = true;
			await this.userStore.validateAuthentication();
		},
		/**
		 * Fetch the active keycloak session within the iframe so the user won't be redirected
		 */
		getLoginSessionWithIframe() {
			if (!this.userAuthenticated && this.userStore.loginRedirectUrl) {
				this.loading = true;
				const dummyFrame = this.$refs.dummyLogin;
				dummyFrame.onload = async () => {
					Q.defer('account-loading', 500, async () => {
						await this.validateUser();
						this.loading = false;
					});
				};
				dummyFrame.src = this.userStore.loginRedirectUrl;
			} else {
				this.loading = false;
			}
		},
		listenForCookieChange() {
			const sessionName = 'MT360_SESSION';
			// use the new cookie store api if available
			if (window.cookieStore !== undefined) {
				cookieStore.addEventListener('change', ({ deleted }) => {
					for (const { name } of deleted) {
						if (name === sessionName) {
							setTimeout(() => {
								location.reload();
							}, 5000);
						}
					}
				});
			} else {
				// otherwise poll the cookie information
				listenCookieChange(({ oldValue, newValue }) => {
					if (oldValue.includes(sessionName) && !newValue.includes(sessionName)) {
						location.reload();
					}
				}, 5000);
			}
		}
	}
};
</script>
