<template lang="pug">
h2.header-with-anchor
	v-divider.mb-4
	a(:id="anchor", :href="`#${anchor}`")
	| {{ text }}
	v-btn.header-anchor.text-decoration-none(
		size="x-small",
		variant="flat",
		icon="link",
		@click="copy2Clipboard()",
		style="user-select: none",
		v-tooltip.bottom="tooltip"
	)
</template>

<script>
import Q from '@mt360/q';

export default {
	name: 'AnchorHeadline',
	data() {
		return {
			tooltip: this.$t('anchorHeadline.tooltips.copyLink')
		};
	},
	props: {
		anchor: {
			type: String,
			default: ''
		},
		link: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		}
	},
	computed: {},
	methods: {
		async copy2Clipboard() {
			try {
				await navigator.clipboard.writeText(this.link);

				// change tooltip text
				this.tooltip = this.$t('anchorHeadline.tooltips.linkCopied');
				setTimeout(() => (this.tooltip = this.$t('anchorHeadline.tooltips.copyLink')), 1000);
			} catch (err) {
				Q.log(`copy2Clipboard: Could not copy text`, err);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@mt360/client-core/styles/text-styles';

.header-anchor {
	float: left;
	margin-left: -40px;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.header-with-anchor:hover .header-anchor {
	opacity: 1;
}

h2 {
	@extend %headline2;
}

a {
	padding-top: 80px;
	margin-top: -40px;
}
</style>
