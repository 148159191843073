export default {
	//samples for HelloWorld.vue
	pick: 'Pick your language',
	msg: 'hello',
	named: '{msg} world!',
	list: '{0} world!',
	literal: "{account}{'@'}{domain}",
	the_world: 'the world',
	dio: 'DIO:',
	linked: '@:dio @:the_world !!!!',
	//end of samples

	//vuetify translation:
	$vuetify: {
		//sample:
		dataIterator: {
			rowsPerPageText: 'Items per page:',
			pageText: '{0}-{1} of {2}'
		}
	},

	//end vuetify translations
	navigation: {
		headers: {
			docs: 'Documentation',
			articles: 'Articles',
			content: 'In this article'
		}
	},

	// ApiDocView.vue
	apiDocumentation: {
		title: 'Playground'
	},

	// DopCalculator.vue
	dopCalculator: {
		title: 'DOPs Calculator',
		intro:
			'This data operations calculator is a simplified version and does not take into account data operations that can be done via API requests, for example.',
		introPackages: 'Here you can see how many days you can get by  each individual Dops package.',
		texts: {
			devices: 'Devices',
			dataPoints: 'Data points',
			recordInterval: 'Record interval',
			dopPerDay: 'Data operations per day',
			dop: 'Data operations',
			minutes: 'Minutes',
			dopPackages: 'DOP Packages',
			day: 'Day',
			days: 'Days'
		}
	},

	// AnchorHeadline.vue
	anchorHeadline: {
		tooltips: {
			copyLink: 'Copy link',
			linkCopied: 'Link copied'
		}
	},

	// HighlightJsHeader.vue
	HighlightJsHeader: {
		copyCode: 'Copy code',
		copied: 'Copied!'
	}
};
