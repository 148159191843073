<template lang="pug">
v-card-title.pa-0.d-flex.flex-row.mt-10(:class="{ 'text-center': textCenter, 'mb-10': !dense, 'mb-4': dense }")
	.flex-column.w-100
		.d-flex.flex-row.align-center
			.headline2 {{ title }}
			BetaLabel.ml-4(v-if="betaLabel")
		.headline4.mt-4(v-if="subtitle.length") {{ subtitle }}
		.subhead1.mt-4(v-else-if="hint.length") {{ hint }}
	v-spacer(v-if="$slots.titleAction")
	.d-flex
		slot(name="titleAction")
slot
</template>

<script>
import BetaLabel from './BetaLabel.vue';

export default {
	components: { BetaLabel },
	props: {
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		hint: {
			type: String,
			default: ''
		},
		textAlign: {
			type: String,
			default: 'left'
		},
		dense: {
			type: Boolean,
			default: false
		},
		betaLabel: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		textCenter() {
			return this.textAlign === 'center';
		}
	}
};
</script>
<style lang="scss" scoped>
.v-card-title {
	.subhead1 {
		white-space: normal;
	}
}
</style>
