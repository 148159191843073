<template lang="pug">
v-app-bar.toolbar-modifications(app, prominent, flat)
	slot
	v-app-bar-title.ml-6
		.d-flex.flex-row.align-center
			a.d-flex.flex-row.align-center(v-if="href", :href="href")
				BaseLogo.logo-mt360
			.d-flex.flex-row.align-center(v-else="href", :href="href")
				BaseLogo.logo-mt360
	v-spacer
	slot(name="content")
	.mr-6
</template>

<script>
import BaseLogo from './BaseLogo.vue';
export default {
	name: 'BaseToolbar',
	components: {
		BaseLogo
	},
	props: {
		href: {
			type: String,
			default: ''
		}
	}
};
</script>

<style lang="scss">
.toolbar-modifications {
	/* stylelint-disable-next-line */
	> .v-toolbar__content {
		/* stylelint-disable-next-line */
		border-bottom: solid thin rgba(var(--v-border-color), var(--v-border-opacity));
	}

	.logo-mt360 {
		max-height: 40px;
		height: 40px;
		margin-right: 48px;
	}
}
</style>
